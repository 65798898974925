/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/21/2020
 */


import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import UserImage from '../../../../common/user/user-image';
import Config from '../../../../../config';
import _ from 'lodash';
import Icons from '../../../../common/icons/index'
import Icon, {ReplaceAllRight, Info, History, Approval, Cancel} from "diginet-core-ui/icons";
// import Rating from "@material-ui/lab/Rating";
import * as W25F2015Actions from "../../../../../redux/W2X/W25F2015/W25F2015_actions";
import * as generalActions from "../../../../../redux/general/general_actions";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {withStyles} from '@material-ui/core';
import {Tooltip, Rating} from "diginet-core-ui/components";
import W25F2040 from "../../W25F2040/W25F2040";
import W84F3005 from "../../../../W8X/W84/W84F3005/W84F3005"
import W25F2303 from "../../W25F2303/W25F2303";
const styles = () => ({
    userImage: {
        height: 24,
        width: 24,
        borderRadius: 20,
    },
    iconMove:{
        // background: "grey",
        // padding: "2px 4px",
        display:"flex",
        alignItems:'center',
        justifyContent:"center",
        width:24,
        height:24,
        borderRadius: "24px 0 0 24px",
        color: "white",
        background: Config.coreTheme?.colors?.secondary
    },
    iconButton: {
        display:"flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        cursor: "pointer"
    }
});

class ItemTemplate extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW25F2040Popup: false,
            showW84F3005Popup: false,
        };
    }
    renderUserImageHover = (user) => {
        const arrUserInfo = _.filter([
            {
                label: Config.lang('Nhan_vien'),
                value: _.join(_.compact(
                    [
                        _.trim(_.get(user, "EmployeeID", "")),
                        _.trim(_.get(user, "EmployeeName", ""))
                    ]), " - ")
            },
            {
                label: Config.lang('Phong_ban'),
                value: _.trim(_.get(user, "DepartmentName", ""))
            },
            {
                label: Config.lang('Du_an'),
                value: _.trim(_.get(user, "ProjectName", ""))
            },
            {
                label: Config.lang('Chuc_vu'),
                value: _.trim(_.get(user, "DutyName", ""))
            },
            {
                label: Config.lang('Ngay_vao_lam'),
                value: _.trim(_.get(user, "dateJoined", ""))
            }
        ], item => Boolean(item.value))
        return _.map(arrUserInfo, (item, i) => <span key={i}>{item.label}: {item.value}<br/></span>)
    }

    renderUserImage = () => {
        const {data, classes} = this.props;
        if (_.isEmpty(data)) return false;
        const {ListMemberFollow} = data;
        const listMember = ListMemberFollow.split(",");
        // get list user by UserID
        const listUsers = Config.getListUser(listMember, "UserID");

        let styleCustom = {
            minHeight: 46
        };
        if(listUsers.length > 1){
            styleCustom = {
                width: 35,
                minWidth: 35,
                height: 35,
                minHeight: 35,
            }
        }
        return <>
            {_.map(_.slice(listUsers, 0, 2), (item, i) => <UserImage
                key={i}
                data={item}
                style={{
                    ...styleCustom,
                    marginRight: i*6 - 6,
                    zIndex: 2-i,}}
                renderItemHover={(user) => this.renderUserImageHover(user)}
                className={classes.userImage}
            />)}
            {_.size(listUsers) > 2 && <i className="fas fa-ellipsis-h" style={{color: "#9597A1"}}/>}
        </>
    };
    closePopup = (key, isSave = false) => {
        if (key === "showW84F3005Popup") {
            this.setState({ showW84F3005Popup: false });
        }
        if (key === "showW25F2040Popup") {
            this.setState({ showW25F2040Popup: false });
        }
    };
    render() {
        const {data, onClick, classes, open, close, showW25F2303Popup, dataChangedStage, changedStage} = this.props;
        if (_.isEmpty(data)) return false;
        let obj = {
            CandidatePictureURL: Config.getUserPicture(data.CandidatePictureURL && data.CandidatePictureURL) || require('../../../../../assets/images/general/user_default.svg'),
            CandidateName: data.CandidateName || "",
            Sex: data.SexName || "",
            BirthDate: data.BirthDate ? moment(data.BirthDate).format("DD/MM/YYYY") : "",
            Mobile: data.Mobile || "",
            EPointAvg: Number.isInteger(data.EPointAvg) ? data.EPointAvg : (Math.ceil(data.EPointAvg) - 0.5) || 0,
            ListMemberFollow: data.ListMemberFollow && data.ListMemberFollow.split(",").length > 2,
            count: data.count || 0,
        };
        const isMoved = !!_.get(data, "IsMoved", 0);
        const isEmployee = !!_.get(data, "IsEmployee", 0);
        const IsApproved = !!_.get(data, "IsApproved", 0);
        const ApprovalStatus = data?.ApprovalStatus;
        const ApproveLevel = data?.ApproveLevel;
        const showIcon = !_.isEmpty(dataChangedStage) ?
            (data?.CandidateID === dataChangedStage?.CandidateID ?
                !!dataChangedStage?.IsNotInterview : !!_.get(data, "IsNotInterview", 0)) :
            !!_.get(data, "IsNotInterview", 0);
        const dataPopup = {
            CandidateID: data?.CandidateID || "",
            InterviewFileID: data?.InterviewFileID || "",
            Language: Config.language || 84,
            RecInfoID: data?.RecInfoID || ""
        };
        return (
            <div onMouseDown={e => {e.stopPropagation()}} style={{height: "100%", width: "100%", position: "relative"}}>
                <W84F3005
                    open={this.state.showW84F3005Popup}
                    onClose={() => {
                        this.closePopup("showW84F3005Popup");
                    }}
                    TransID={data?.DecisionID || ""}
                />
                <W25F2040
                    open={this.state.showW25F2040Popup}
                    onClose={() => {
                        this.closePopup("showW25F2040Popup");
                    }}
                    RecInfoID={data?.RecInfoID || ""}
                    CandidateID={data?.CandidateID || ""}
                    TransID={data?.DecisionID || ""}
                    mode={1}
                    readOnly={ApproveLevel !== 1 || ApprovalStatus !== 0}
                    fullCandidateInfo={data || {}}
                />
                {showW25F2303Popup ? <W25F2303
                    open={showW25F2303Popup}
                    data={dataPopup}
                    mode={"add"}
                    onClose={close}
                    FormID="W25F2023"
                    disableIconSave={true}
                    changedStage={changedStage}
                /> : ""}
                <div className='display_row align-left' style={{height: "100%", justifyContent: "space-between"}}>
                    <div style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: 'relative'
                    }}>
                        <img
                            alt=""
                            className={"mgr5"}
                            style={{height: 40, width: 40, borderRadius: 20, objectFit: "cover", cursor: "pointer"}}
                            src={obj.CandidatePictureURL}
                            onClick={() => {
                                onClick()
                            }}
                        />
                        {showIcon ?
                            <div style={{
                                position:"absolute",
                                top: 20,
                                left: 10,
                                cursor: 'pointer',
                                backgroundColor: "#FFF",
                                borderRadius: '50%'}}>
                                <Tooltip title={Config.lang("Ung_vien_chua_co_lich_phong_van")}>
                                    <Icon
                                        name={"CalendarBlankRounded"}
                                        color={"#FF3D71"}
                                        width={24}
                                        height={24}
                                        onClick={() => {
                                            open()
                                        }}
                                        viewBox
                                    />
                                </Tooltip>
                            </div>
                            : ""
                        }

                        <div className="align-center display_row">
                                    <span className="tasks-header ">
                                        {obj.count}
                                    </span>
                            <Icons name={"person-done"} width={24} height={24}/>
                        </div>
                    </div>
                    <div style={{
                        width: 'calc(100% - 45px)',
                        height: "100%",
                        justifyContent: "space-between"}} className="display_col">
                        <div style={{display: "flex", justifyContent: "space-between", alignItems: "flex-start"}}>
                            <div>
                                <div className="full-name"
                                     onClick={() => onClick()}
                                     style={{cursor: "pointer"}}>
                                    {obj.CandidateName}
                                </div>
                                <div className="sex-birthday">
                                    {obj.Sex}, {obj.BirthDate}
                                </div>
                                <div className="sex-birthday">{obj.Mobile}</div>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {this.renderUserImage()}
                            </div>
                            <div className={"display_row align-center align-between"}>
                                <Rating
                                    quantity={5}
                                    value={obj?.EPointAvg ?? 0}
                                    half={true}
                                    readOnly
                                    width={24}
                                    color={"warning"}
                                    style={{paddingBottom: 2, marginLeft: -5}}
                                    starStyle={{
                                        padding: "0 3px"
                                    }}
                                />
                                {IsApproved && (
                                    <div className={classes.iconButton} onClick={() => this.setState({ showW84F3005Popup: true })}>
                                        <History width={20} />
                                    </div>
                                )}
                                {IsApproved && (
                                    <div className={classes.iconButton} onClick={() => this.setState({ showW25F2040Popup: true })}>
                                        {ApprovalStatus === 0 && <Approval width={20} />}
                                        {ApprovalStatus === 1 && <Approval color={"info"} width={20} />}
                                        {ApprovalStatus === 2 && <Cancel color={"danger"} width={20} />}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Tooltip
                            title={
                                isMoved
                                    ? Config.lang("Da_chuyen")
                                    : isEmployee
                                    ? Config.lang(
                                        "Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay"
                                    )
                                    : ""
                            }
                            arrow
                            direction={"down"}
                            style={{ position: "absolute", bottom: 37, right: -15 }}
                        >
                            <div className={isMoved || isEmployee ? classes.iconMove : ""}>
                                {isMoved ? <ReplaceAllRight color={"white"} width={20} /> : isEmployee ? <Info color={"white"} width={20} /> : ""}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    }
}

ItemTemplate.propTypes = {
    onClick: PropTypes.func,
    data: PropTypes.object,
    open: PropTypes.func,
    close: PropTypes.func,
    showW25F2303Popup: PropTypes.bool,
    dataChangedStage: PropTypes.object,
    changedStage: PropTypes.func,
};

export default compose(
    connect(null,
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W25F2015Actions: bindActionCreators(W25F2015Actions, dispatch)
        })
    ),
    withStyles(styles, {withTheme: true})
)(ItemTemplate);