/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/28/2020
 * @Example
 */
import React                         from "react";
import {connect}                     from "react-redux";
import * as W25F2011Actions          from "../../../../redux/W2X/W25F2011/W25F2011_actions";
import * as generalActions           from "../../../../redux/general/general_actions";
import {bindActionCreators, compose} from "redux";
import Config                        from "../../../../config";
import {FormGroup}                   from "react-bootstrap"
import ButtonGeneral                 from "../../../common/button/button-general";
import withStyles                    from "@material-ui/core/styles/withStyles";
import ActionToolbar                 from "../../../common/toolbar/action-toolbar";
import moment                        from "moment";
import {browserHistory}              from "react-router";
import Icons                         from "../../../common/icons/";
import {Tab, Tabs}                   from "@material-ui/core";
import TabContent, {TabPanel} from "../../../common/tabs/tab-content";
import W25F2011Info           from "./W25F2011Info";
import W25F2011Criteria       from "./W25F2011Criteria";
import * as _                 from "lodash";
import W25F2011WorkFlow       from "./W25F2011WorkFlow";
import W25F2011Questions      from "./W25F2011Questions";
import W25F2011Status         from "./W25F2011Status";
import PopupSelect2           from "../../../popup/popup-select2";
import PropTypes              from "prop-types";

const styles = theme => ({
    iconButton: {
        width: 30,
        height: 30,
        position: "absolute",
        left: "1rem",
        top: 0
    },
    sideBarTabs: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '-15px',
        position: "fixed",
        top: 50,
        boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.1)',
        height: 'calc(100vh - 55px)',
        paddingTop: 32,
        [theme.breakpoints.down('xs')]: {
            width: '40px',
            overflow:'hidden',
        },
    },
    tab: {
        minWidth: '200px',
        height: 32,
        minHeight: 32,
        float: 'left',
        paddingLeft: '-15px',
        zIndex: 1,
        color: "#151A30",
        fontWeight: 400,
        "&:after": {
            content: "none"
        },
        "&.Mui-selected": {
            color: "#FFFFFF",
            '& svg': {
                '& path': {
                    fill: "#FFFFFF"
                }
            }
        }
    },
    tabContent : {
        width: "calc(100% - 218px)",
        position: "relative",
        top: 0,
        left: 232,
        [theme.breakpoints.down('xs')]: {
            padding: "0px 15px",
            width: '100%',
            left: 20,
        },
    },
    bgIndicator: {
        left: 0,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    allowDrag: {
        "& .react-swipeable-view-container": {
            willChange: "auto !important",
            transform: "none !important",
            display: "block !important"
        }
    },
    actionToolbar:{
        width: `calc(100% - 330px) !important`,
        marginLeft:`0 !important`,
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 55px) !important',
        },
    }
});
const tabs = [
    {
        ID: 0,
        name: "DHR_Thong_tin_tuyen_dung",
        icon: require("./icons/info.svg"),
        data: null,
    },
    {
        ID: 1,
        name: "DHR_Tieu_chi_danh_gia",
        icon: require("./icons/criteria.svg"),
        data: null,
    },
    {
        ID: 2,
        name: "DHR_Quy_trinh_tuyen_dung_Workflow",
        icon: require("./icons/workflow.svg"),
        data: null,
    },
    {
        ID: 3,
        name: "DHR_Cau_hoi_phong_van",
        icon: require("./icons/question.svg"),
    },
    {
        ID: 4,
        name: "DHR_Dang_tin_tuyen_dung",
        icon: require("./icons/status.svg"),
        data: null,
    },
];
class W25F2011 extends React.Component {
    constructor(props) {
        super(props);
        // if (!this.getInfo()) return;
        this.state = {
            tabKey: 0,
            dataForm: {
                mode: "add",
                RecInfoID: "", //804041A9-A2B1-410A-842A-F52159249B07
                RecInfoTitle: "",
                RecPositionID: "", //00001
            },
            showSelectRecInfo: false,
            dataSuggestions: [],

            gridLoading: false,
            loading: false,
            isPermission: 0,
            isUpdate: false,
            isReset: false,
            isResetTab: {},
            isCopy: false,
            isLoadFirst: false,
        };
        this.tabInfo = null;
        this.tabQuestion = null;
        this.dataForm = {}};


    async loadPermission() {
        await this.props.generalActions.getPermission("W25F2010", (isPer) => {
            this.setState({isPermission: isPer});
        });
    };

    loadForm = (mode = 0, cb) => {
        const { RecInfoID } = this.getInfo();
        const params = {
            FormID: "W25F2011",
            Language: Config.language || "84",
            RecInfoID: RecInfoID || "",
            // Key01ID: "",
            Mode: mode
        };
        this.setState({ loading: true });
        this.props.W25F2011Actions.getForm(params, (error, data) => {
            this.setState({ loading: false });
            if (error && !error.cancel) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (mode === 0 && data) {
                this.setState(prevState => ({
                    dataForm: {
                        ...prevState.dataForm,
                        RecInfoTitle: data.RecInfoTitle || "",
                        RecPositionID: data.RecPositionID || ""
                    }
                }));
            }
            if ([0,3].includes(mode)) {
                this.setState({isLoad: true});
            }
            cb && cb(error, data);
        });
    };

    loadGridSuggestions = (strSearch) => {
        const params = {
            RecInfoTitle: strSearch,
        };
        this.props.W25F2011Actions.getCboSuggestions(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({dataSuggestions: data ? data : []});
            }
        });
    };

    getInfo = () => {
        const { location } = this.props;
        const { isCopy, dataForm } = this.state;
        let RecInfoID = dataForm && dataForm.RecInfoID ? dataForm.RecInfoID : "";
        let mode = dataForm && dataForm.mode ? dataForm.mode : "add";
        if (location && location.state) {
            RecInfoID = location.state.RecInfoID ? location.state.RecInfoID : RecInfoID;
            return {
                mode: RecInfoID && !isCopy ? "edit" : (location.state.mode ? location.state.mode : mode),
                RecInfoID: location.state.RecInfoID ? location.state.RecInfoID : RecInfoID,
                ...(location.state.RecPositionID ? {RecPositionID: location.state.RecPositionID} : {})
            }
        }
        return {mode: mode, RecInfoID: RecInfoID};
    };

    componentDidMount = async () => {
        // if (!this.getInfo(true)) return false;
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        const {mode, RecInfoID, RecPositionID} = this.getInfo();
        this.setState(prevState => ({
            dataForm: {
                ...prevState.dataForm,
                mode: mode,
                RecInfoID: RecInfoID,
                ...(RecPositionID ? {RecPositionID: RecPositionID} : {})
            },
            ...(this.state.isPermission < 2 ? {disabled: true} : {})
        }));
    };

    componentWillUnmount = () => {
        this.setState(null);
        this.tabInfo = null;
        this.tabQuestion = null;
        this.dataForm = {};
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W25F2010");
    };

    handleTabChange = (e, value) => {
        let {disabled, isUpdate, tabKey, isResetTab} = this.state;
        if (isUpdate && !disabled) {
            Config.popup.show("YES_NO", Config.lang("DHR_Ban_co_du_lieu_chua_duoc_luu_Ban_co_muon_tiep_tuc"), () => {
                this.setState({
                    isResetTab: {...isResetTab, [tabKey]: true},
                    tabKey: value,
                    isUpdate: false
                }, () => {
                    isResetTab = this.state.isResetTab;
                    tabKey = this.state.tabKey;
                    if (isResetTab[tabKey]) {
                        this.setState({isResetTab: {...isResetTab, [tabKey]: false}});
                    }
                });
            });
        } else {
            this.setState({
                isResetTab: {...isResetTab, [tabKey]: false},
                tabKey: value,
                isUpdate: false
            }, () => {
                isResetTab = this.state.isResetTab;
                tabKey = this.state.tabKey;
                if (isResetTab[tabKey]) {
                    this.setState({isResetTab: {...isResetTab, [tabKey]: false}});
                }
            });
        }
    };

    _compareChanged = (newData, oldData) => {
        let isChange = false;
        if (newData && oldData) {
            const keys = Object.keys(newData);
            for(let key of keys) {
                switch (key) {
                    case "ContentInfo":
                    case "QuestionContent":
                        if (!this.state.isLoad || !oldData[key]) {
                            const _newText = newData[key].indexOf('<p>') === 0 ? newData[key] : "<p>" + newData[key] + "</p>";
                            const _oldText = oldData[key].indexOf('<p>') === 0 ? oldData[key] : "<p>" + oldData[key] + "</p>";
                            if (_newText !== _oldText) {
                                isChange = true;
                            }
                            if (!oldData[key]) this.setState({isLoad: false});
                        } else {
                            this.setState({isLoad: false});
                        }
                        break;
                    case "ListMember":
                        if (JSON.stringify(newData[key]) !== JSON.stringify(oldData[key])) {
                            isChange = true;
                        }
                        break;
                    case "ReceiveDateFrom":
                    case "ReceiveDateTo":
                    case "Deadline":
                        if (moment(newData[key]).format("YYYY-MM-DD") !== moment(oldData[key]).format("YYYY-MM-DD")) {
                            isChange = true;
                        }
                        break;
                    default:
                        if (String(newData[key]) !== String(oldData[key])) {
                            isChange = true;
                        }
                        break;
                }
                if (isChange) break;
            }
            // Object.keys(newData).forEach(key => {
            //     if (key !== "ContentInfo" && newData[key] !== oldData[key]) return true;
            // });
        }
        return isChange;
    };

    handleChange = (key, e, newData, oldData) => {
        if (!key || !e) return false;
        const {disabled} = this.state;
        if (!disabled) {
            if (this.timer) clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.setState({isUpdate: this._compareChanged(newData, oldData)});
            }, 400);
        }
        if (key === "RecPositionID") {
            const value  = _.get(e, "value", _.get(e, "target.value", e));
            this.setState(prevState => ({
                dataForm: {...prevState.dataForm, RecPositionID: value}
            }));
        }
    };

    onSave = async () => {
        const {tabKey} = this.state;
        switch (tabKey) {
            case 0:
                if (this.tabInfo) {
                    this.setState({loading: true});
                    const data = this.tabInfo.getData();
                    let dataSaved = await this.tabInfo.saveData();
                    this.setState({loading: false});
                    if (dataSaved && dataSaved.success) {
                        if (dataSaved.data) {
                            dataSaved = dataSaved.data;
                            const RecInfoID = dataSaved.RecInfoID ? dataSaved.RecInfoID : (data && data.RecInfoID ? data.RecInfoID : "");
                            this.setState({
                                dataForm: {
                                    ...this.state.dataForm,
                                    ...(RecInfoID ? {mode: "edit"} : {}),
                                    RecInfoID:     dataSaved.RecInfoID ? dataSaved.RecInfoID : (data && data.RecInfoID ? data.RecInfoID : ""),
                                    RecInfoTitle:  data && data.RecInfoTitle ? data.RecInfoTitle : "",
                                    RecPositionID: dataSaved.RecpositionID ? dataSaved.RecpositionID : (data && data.RecPositionID ? data.RecPositionID : ""),
                                },
                                isCopy:   false,
                                isUpdate: false,
                            }, () => {
                                this._pushData();
                            });
                        } else {
                            Config.popup.show("INFO", Config.lang("DHR_Thong_tin_tra_ve_khi_luu_khong_dung"));
                        }
                    }
                } else {
                    Config.popup.show("INFO", Config.lang("DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                }
                break;
            case 3:
                if (this.tabQuestion) {
                    this.setState({loading: true});
                    await this.tabQuestion.saveData();
                    this.setState({loading: false, isUpdate: false});
                } else {
                    Config.popup.show("INFO", Config.lang("DHR_Co_loi_xay_ra_trong_qua_trinh_xu_ly"));
                }
                break;
            default:
                break;
        }
    };

    _pushData = (mode) => {
        const {dataForm} = this.state;
        const {mode: _mode} = this.getInfo();
        mode = mode ? mode : _mode;
        browserHistory.push({
            pathname: Config.getRootPath() + "W25F2011",
            state: {
                mode: mode,
                RecInfoID: dataForm && dataForm.RecInfoID ? dataForm.RecInfoID : "",
                RecPositionID: dataForm.RecPositionID ? dataForm.RecPositionID : ""
            }
        });
    };

    toggleSelectRecInfo = (isClose) => {
        this.setState({
            showSelectRecInfo: !isClose
        }, () => {
            if (!isClose) {
                this.loadGridSuggestions("");
            }
        });
    };

    onSearchSuggestions = (e) => {
        const {strSearch} = e;
        this.loadGridSuggestions(strSearch);
    };

    chosenCopy = (e) => {
        if (!e || e.length < 1) return false;
        const RecInfoID = e[0];
        const {mode} = this.getInfo();
        this.setState({
            dataForm: {
                mode: mode,
                RecInfoID: RecInfoID
            },
            isReset: true,
            tabKey: 0,
            isCopy: true,
            isUpdate: true
        }, () => {
            this.setState({isReset: false});
        });
    };

    render() {
        if (!this.getInfo()) return null;
        const {mode} = this.getInfo();
        const { classes } = this.props;
        const { isCopy, isResetTab, isUpdate, tabKey, disabled, isReset, loading, dataForm,
                  isPermission, showSelectRecInfo, dataSuggestions
        } = this.state;
        if (!isPermission) return null;
        const allowEdit = dataForm && dataForm.RecInfoID && !isReset && !isCopy;//(mode === "add" && isSaved) || mode !== "add";
        const tabAllowSave = [0, 3];
        const columns = [
            {
                caption: "",
                dataField: "RecInfoID",
                visible: false
            },
            {
                caption: Config.lang("DHR_Tieu_de_tin"),
                dataField: "RecInfoTitle"
            }
        ];

        return (
            <React.Fragment>
                {showSelectRecInfo && <PopupSelect2
                    ref={ref => this.popupProject = ref}
                    itemPerPage={10}
                    keyExpr={"RecInfoID"}
                    title={Config.lang("DHR_Danh_sach_tin_da_dang")}
                    open={showSelectRecInfo}
                    dataSource={dataSuggestions}
                    typePaging={"normal"}
                    column={columns}
                    onSearch={this.onSearchSuggestions}
                    onClose={() => this.toggleSelectRecInfo(true)}
                    onChosen={(e) => this.chosenCopy(e)}
                />}
                <div className={"display_row"}>
                    <div className={classes.sideBarTabs}>
                        <Tabs
                            value={tabKey}
                            onChange={this.handleTabChange}
                            indicatorColor='primary'
                            textColor="primary"
                            style={{fontSize: '16px', width: "240px"}}
                            aria-label="Vertical tabs example"
                            ScrollButtonComponent={this.renderButtonTab}
                            orientation="vertical"
                            classes={{ indicator: classes.bgIndicator }}
                        >
                            {tabs && tabs.map(tab => {
                                const icon = <Icons src={tab.icon ? tab.icon : require("../../../../assets/images/default/default.svg")}
                                                    width={17} height={17}
                                            />;
                                return <Tab
                                    key={tab.ID}
                                    disabled={tab.ID !== 0 && (!allowEdit || loading)}
                                    className={classes.tab}
                                    classes={{root: classes.rootTab, selected: classes.selected, wrapper: classes.wrapper}}
                                    label={<>
                                        <div className={"display_row align-center valign-top"} style={{width: "100%"}}>
                                            <span className={"display_row align-center mgr10"} style={{marginTop: -1}}>{icon}</span>
                                            <span style={{fontSize: '12px'}}>{Config.lang(tab.name) || ""}</span>
                                        </div>
                                    </>}
                                />
                            })}
                        </Tabs>
                    </div>
                    <div style={{clear: "both"}} />
                    <div className={classes.tabContent + (tabKey === 2 ? " " + classes.allowDrag : "")}>
                        <FormGroup>
                            <ActionToolbar
                                title={Config.lang("Chi_tiet_tin_tuyen_dung")}
                                onBack={this.onBack}
                                alignment={"flex-end"}
                                className={classes.actionToolbar}
                            >
                                <div className={"pdl15 pdr15"}>
                                    <ButtonGeneral
                                        name={Config.lang("DHR_Sao_chep_tin_dang")}
                                        color={"primary"}
                                        variant={"outlined"}
                                        className={"mgr5"}
                                        icon={<Icons name={"copy"} color={"primary"}/>}
                                        style={{textTransform: "uppercase"}}
                                        size={"large"}
                                        disabled={disabled || allowEdit || mode === "edit"}
                                        onClick={this.toggleSelectRecInfo}
                                    />
                                    <ButtonGeneral
                                        name={Config.lang('DHR_Luu')}
                                        typeButton={'save'}
                                        disabled={disabled || (!isCopy && !isUpdate) || !tabAllowSave.includes(tabKey)}
                                        loading={loading}
                                        style={{textTransform: 'uppercase'}}
                                        size={"large"}
                                        onClick={this.onSave}/>
                                </div>
                            </ActionToolbar>
                        </FormGroup>
                        <TabContent
                            style={{padding:     "0 15px",
                                backgroundColor: '#fff'
                            }}
                            activeKey={tabKey}
                            disableSwipe={true}
                            lazyLoading={false}
                            onChangeIndex={this.handleTabChange}>
                            <TabPanel index={0}>
                                {!isResetTab[0] && <W25F2011Info
                                    ref={ref => this.tabInfo = ref}
                                    data={dataForm}
                                    disabled={disabled}
                                    isCopy={isCopy}
                                    loadForm={this.loadForm}
                                    onChange={this.handleChange}
                                    btnSave={
                                        <ButtonGeneral
                                            name={Config.lang('DHR_Luu')}
                                            typeButton={'save'}
                                            disabled={disabled || (!isCopy && !isUpdate) || !tabAllowSave.includes(tabKey)}
                                            loading={loading}
                                            style={{textTransform: 'uppercase'}}
                                            size={"large"}
                                            onClick={this.onSave}/>
                                    }
                                />}
                            </TabPanel>
                            <TabPanel index={1}>
                                {tabKey === 1 && allowEdit &&
                                <W25F2011Criteria loadForm={this.loadForm} data={dataForm} disabled={disabled}/>}
                            </TabPanel>
                            <TabPanel index={2}>
                                {allowEdit && <W25F2011WorkFlow loadForm={this.loadForm} data={dataForm} disabled={disabled}/>}
                            </TabPanel>
                            <TabPanel index={3}>
                                {!isResetTab[3] && allowEdit &&
                                <W25F2011Questions ref={ref => this.tabQuestion = ref} loadForm={this.loadForm}
                                                   disabled={disabled}
                                                   data={dataForm} onChange={this.handleChange}/>}
                            </TabPanel>
                            <TabPanel index={4}>
                                {allowEdit && <W25F2011Status
                                    loadForm={this.loadForm}
                                    data={dataForm}
                                    disabled={disabled}
                                    onChange={this.handleChange}
                                />}
                            </TabPanel>
                        </TabContent>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

W25F2011.propTypes = {
    mode: PropTypes.string,
    RecInfoID: PropTypes.string,
    RecPositionID: PropTypes.string,
};

export default compose(connect(null,
    (dispatch) => ({
        W25F2011Actions: bindActionCreators(W25F2011Actions, dispatch),
        generalActions:  bindActionCreators(generalActions, dispatch),
    })), withStyles(styles))(W25F2011);
