import React, {Component} from "react";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import {Col, FormGroup, Image, Row} from "react-bootstrap";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W25F2012Actions from "../../../../redux/W2X/W25F2012/W25F2012_actions";
import Config from "../../../../config";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Divider,
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Icon,
    IconButton,
    LinearProgress,
    Menu,
    MenuItem,
    Slider,
    TextField,
    Tooltip,
    Typography,
    withStyles,
    Breadcrumbs
} from "@material-ui/core";
import _ from "lodash";
import Rating from "@material-ui/lab/Rating";
import ButtonGeneral from "../../../common/button/button-general";
import ButtonCustom from "../../../common/button/button-custom";
import Icons from "../../../common/icons";
import Api from "../../../../services/api";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import UserImage from "../../../common/user/user-image";
import moment from "moment";
import UserName from "../../../common/user/user-name";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import {Info} from 'diginet-core-ui/icons';
import {ReplaceAllRight} from "diginet-core-ui/icons";
import W25F2040 from "../W25F2040/W25F2040";
import SendMailPop from "../W25F2015/utils/SendMailPop";

const styles = theme => ({
    boxShadowCard: {
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    },
    cardCandidate: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    cardColumnCenter: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            alignItems: "center",
            padding: "15px 0 0"
        }
    },
    boxShadow: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
    },
    iconRedo: {
        "&:hover": {
            color: theme.palette.text.subtext
        }
    },
    iconCheck: {
        "&:hover": {
            color: theme.palette.success.main
        }
    },
    buttonAction: {
        flex: 1,
        justifyContent: "space-around",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        },
        "& button": {
            marginBottom: 5
        }
    },
    buttonStage: {
        width: "100%",
        margin: "0 7px",
        "& .MuiIcon-root": {
            width: "1.1em",
            height: "1.1em"
        }
    },
    iconSuccess: {
        color: theme.palette.success.main
    },
    iconDanger: {
        color: theme.palette.danger.main
    },
    btnSuccess: {
        backgroundColor: theme.palette.success.main + "!important",
        color: "#FFF"
    },
    btnDanger: {
        backgroundColor: theme.palette.danger.main + "!important",
        color: "#FFF"
    },
    slider: {
        "& .MuiSlider-mark": {
            height: 12,
            width: 12,
            borderRadius: "50%",
            backgroundColor: theme.palette.text.subtext,
            marginTop: -5,
            transition: "0.4s",
            "&:hover": {
                transform: "scale(1.2)",
                transition: "fill 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
            }
        },
        "& .MuiSlider-markActive": {
            opacity: 1,
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            marginTop: -5,
            transition: "0.4s"
        },
        "& .MuiSlider-thumb": {
            display: "none"
        },
        "& .MuiSlider-track": {
            backgroundColor: theme.palette.primary.main,
        }
    },
    primaryText: {
        color: theme.palette.primary.main,
        fontWeight: 600
    },
    subText: {
        color: theme.palette.text.subtext,
        fontWeight: 400
    },
    expansionSummaryRoot: {
        minHeight: "45px !important",
        "& .MuiTypography-h6": {
            color: theme.palette.text.primary,
            fontSize: "1.2rem !important",
            fontWeight: "600 !important"
        }
    },
    expansionSummaryContent: {
        margin: "0 !important",
        "&.withSubTitle": {
            flexDirection: "column",
            padding: "10px 0"
        }
    },
    expansionPanelDetails: {
        flex: 1,
        flexDirection: "column"
    },
    textLabelOutlined: {
        "& .MuiInputBase-root": {
            marginTop: 32,
            border: `1px solid ${theme.palette.text.subtext}`,
            borderRadius: 4
        }
    },
    textFieldOutlined: {
        "& > fieldset": {
            border: `1px solid ${theme.palette.text.subtext}`,
            borderRadius: 4
        }
    },
    textLabel: {
        "& .MuiInputBase-root": {
            padding: 10
        },
        "& .MuiInput-underline:before": {
            content: "none"
        },
        "& .MuiFormLabel-root": {
            width: "100%",
            color: theme.palette.text.subtext,
            fontWeight: "600 !important"
        }
    },
    editingComment: {
        opacity: 1
    },
    tlContainer: {
        maxHeight: 320,
        overflowY: "auto",
        overflowX: "hidden",
        padding: "14px 5px"
    },
    tl: {
        "& > :last-child": {
            "&:after": {
                content: "none"
            }
        }
    },
    dashedBox: {
        padding: "16px 0px",
        borderTopWidth: 3,
        borderTopColor: "rgba(150, 152, 162, 0.45)",
        borderTop: "dashed"
    },
    flexDColumn: {
        display: "flex",
        flexDirection: "column"
    },
    flexSpaceBetween: {
        display: "flex",
        justifyContent: "space-between"
    },
    timeLine: {
        position: "relative",
        "&:after": {
            content: "''",
            position: "absolute",
            width: 2,
            borderRadius: 1,
            backgroundColor: theme.palette.text.subtext,
            top: 0,
            bottom: 0,
            left: 0
        }
    },
    containerTimeline: {
        position: "relative",
        left: 20,
        "&:after": {
            content: "''",
            position: "absolute",
            width: 8,
            height: 8,
            left: -22.6,
            backgroundColor: theme.palette.text.subtext,
            top: 0,
            borderRadius: "50%",
            zIndex: 1
        }
    },
    buttonActionComment: {
        paddingLeft: 15
    },
    contentTimeline: {
        transform: "translateY(-10px)",
        paddingBottom: 13,
        cursor: "pointer",
        "&:hover $hoverActionTimeline": {
            opacity: 1,
            transition: "0.6s"
        }
    },
    inputComment: {
        width: "96%"
    },
    editedComment: {
        "& .MuiInputBase-root": {
            "&.MuiOutlinedInput-multiline": {
                padding: 0
            },
            "& > fieldset": {
                border: "none"
            }
        }
    },
    hoverActionTimeline: {
        display: "flex",
        opacity: Config.isMobile ? 1 : 0,
        paddingLeft: 5,
        transition: "0.6s"
    },
    rating: {
        "& .MuiRating-icon.MuiRating-iconFilled": {
            top: 1
        }
    },
    titleScore: {
        display: "flex",
        justifyContent: Config.isMobile ? "center" : "flex-start"
    },
    ratingScore: {
        alignItems: Config.isMobile ? "center" : "flex-end"
    },
    flexDRow: {
        display: "flex",
        alignItems: "center"
    },
    labelProgress: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: 12
    },
    progress: {
        borderRadius: 5,
        height: 8
    },
    avatarPoint: {
        backgroundColor: theme.palette.primary.main
    },
    candidateAvatar: {
        paddingRight: 15,
        [theme.breakpoints.down("sm")]: {
            paddingRight: 0,
        },
    },
    colSlider: {
        width: "96%"
    },
    colAvatar: {
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            justifyContent: "center"
        }
    },
    btnSave: {
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    boxInfoUser: {
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    fillLoading: {
        fill: theme.palette.primary.main + "!important",
    },
    tooltipSlider: {
        width: 600
    },
    iconStatus: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: -15,
        top: 10,
        width: 34,
        height: 34,
        borderRadius: "24px 0 0 24px",
        color: "white",
        background: Config.coreTheme?.colors?.secondary
    }
});

class W25F2012 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: [],
            anchorEl: null,
            dataUserComment: [],
            dataUserEvaluation: [],
            dataUser: [],
            note: "",
            isReadOnly: this?.props?.data?.isReadOnly || false,
            messErrCandidateHired: "",
            isRecommend: false,
            isSaving: false,
            isLoadingAction: false,
            isLoadingCandidateInfo: false,
            isLoadingUserEvaluation: false,
            isLoadingOtherEvaluation: false,
            dataCandidateInfo: {},
            showW25F2040Popup: false,
            isOpenSendMailPop: false,
            currentStage: null,
        };
        this.params = {
            FormID: "W25F2012",
            Language: Config.language || 84,
        };
        this.oldDataUserComment = null;
        this.timerCandidateInfo = null;
        this.timerUserEvaluaton = null;
        this.timerOtherEvaluation = null;
    }

    clsx = (...spread) => _.join(spread, " ");

    getCandidateInfo = () => ({
        CandidateID: _.get(this.props, "data.CandidateID", ""),
        RecInfoID: _.get(this.props, "data.RecInfoID", ""),
        InterviewFileID: _.get(this.props, "data.InterviewFileID", ""),
    });

    getStageName = (stageID) => {
        const {loadStage} = this.props;
        if (!stageID) return "";
        const getIndexStage = _.findIndex(loadStage, o => o.StageID === stageID);
        return _.get(loadStage, `[${getIndexStage}]StageName`, "");
    };

    loadStage = () => {
        this.props.w25f2012Actions.loadStage(_.pick(this.getCandidateInfo(), ["RecInfoID"]), err => {
            if (err) Config.popup.show("ERROR", err);
        });
    };

    loadCheckCandidateHired = async () => {
        const {RecInfoID, CandidateID} = this.getCandidateInfo();
        const params = {
            FieldName: "IsEmployee",
            Values: "",
            Key01ID: RecInfoID,
            Key02ID: CandidateID,
            Mode: 0,
            Language: this.params.Language
        }
        const res = await Api.put("/w25f2012/check", params);
        const resData = _.get(res, "data[0]", _.get(res, "data", {}));
        const resDataStatus = _.get(resData, "Status", 0);
        const resDataMessages = _.get(resData, "Message", "") || Config.lang("Loi_chua_xac_dinh");
        if (resDataStatus === 1) {
            this.setState({isReadOnly: true, messErrCandidateHired: resDataMessages})
        }
    };

    loadInfoCandidate = () => {
        if (this.timerCandidateInfo) clearTimeout(this.timerCandidateInfo);
        this.props.w25f2012Actions.loadInfoCandidate({...this.params, ...this.getCandidateInfo()}, (err, data) => {
            this.timerCandidateInfo = setTimeout(() => {
                this.setState({isLoadingCandidateInfo: false, dataCandidateInfo: (_.get(data, "[0]", {}))});
            }, 1000);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (_.get(data, "[0].StageID", "") === "HIRED") this.loadCheckCandidateHired()
        });
    };

    loadUserEvaluation = () => {
        if (this.timerUserEvaluaton) clearTimeout(this.timerUserEvaluaton);
        this.props.w25f2012Actions.loadUserEvaluation({...this.params, ...this.getCandidateInfo()}, (err, data) => {
            this.timerUserEvaluaton = setTimeout(() => {
                this.setState({isLoadingUserEvaluation: false})
            }, 1000);
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (data) {
                const dataUserComment = _.get(data, "[0]Comment", []);
                const dataUserEvaluation = _.get(data, "[0]Evaluation", []);
                const note = _.get(data, "[0]PrivateNote", "");
                const isRecommend = _.get(data, "[0]IsRecommend", 0) === 1;
                this.setState({dataUserComment, dataUserEvaluation, note, isRecommend}, () => {
                    this.oldDataUserComment = _.get(dataUserComment, "Comment", []);
                });
            }
        });
    };

    loadOtherEvaluation = () => {
        if (this.timerOtherEvaluation) clearTimeout(this.timerOtherEvaluation);
        this.props.w25f2012Actions.loadOtherComment({...this.params, ...this.getCandidateInfo()}, (err) => {
            if (err) Config.popup.show("ERROR", err);
            this.timerOtherEvaluation = setTimeout(() => {
                this.setState({isLoadingOtherEvaluation: false});
            }, 1000)
        });
    };

    handleActionComment = (i = 0, typeEdit = "", value = "") => {
        const {dataUserComment} = this.state;
        switch (typeEdit) {
            case "isEditing":
                this.oldDataUserComment = dataUserComment;
                this.setState({
                    dataUserComment:
                        [
                            ..._.map(_.slice(dataUserComment, 0, i), item => ({
                                ...item,
                                isEditing: false
                            })),
                            {
                                ...dataUserComment[i],
                                [typeEdit]: value,
                            },
                            ..._.map(_.slice(dataUserComment, i + 1), item => ({
                                ...item,
                                isEditing: false
                            }))
                        ]
                });
                break;
            case "Content":
                this.setState({
                    dataUserComment: [
                        ..._.slice(dataUserComment, 0, i),
                        {
                            ...dataUserComment[i],
                            [typeEdit]: value,
                        },
                        ..._.slice(dataUserComment, i + 1)
                    ]
                });
                break;
            case "add":
                const dataAdding = _.concat(_.map(dataUserComment, item => ({...item, isEditing: false})), {
                    Content: "",
                    CreateDate: moment(),
                    isEditing: true
                });
                this.oldDataUserComment = dataAdding;
                this.setState({
                    dataUserComment: dataAdding
                });
                break;
            case "remove":
                const dataRemoving = _.remove(dataUserComment, (_, idx) => idx !== i);
                this.oldDataUserComment = dataRemoving;
                this.setState({
                    dataUserComment: dataRemoving
                });
                break;
            case "redo":
                this.setState({
                    dataUserComment: [
                        ..._.slice(dataUserComment, 0, i),
                        {
                            ...dataUserComment[i],
                            Content: this.oldDataUserComment[i].Content
                        },
                        ..._.slice(dataUserComment, i + 1)
                    ]
                });
                break;
            default:
                return;
        }
    };

    handleMeanEpoint = (data) => {
        const sumEpoint = _.sumBy(data, (exp1) => _.divide(
            _.multiply(
                Number(_.get(exp1, "EPoint", 0)),
                Number(_.get(exp1, "Proportion", 0))
            ),
            _.sumBy(data, (exp2) => Number(_.get(exp2, "Proportion", 0)))
            )
        );
        return Math.round(sumEpoint * 100) / 100;
    }

    onChangeNote = e => {
        this.setState({note: _.get(e, "target.value", "")});
    };

    onChangeEvaluation = (i, value, type = "EPoint") => {
        const {dataUserEvaluation} = this.state;
        this.setState({
            dataUserEvaluation: [
                ..._.slice(dataUserEvaluation, 0, i),
                {
                    ...dataUserEvaluation[i],
                    [type]: value
                },
                ..._.slice(dataUserEvaluation, i + 1),
            ]
        })

    };

    onChangeIsRecommend = () => {
        const {isRecommend} = this.state;
        this.setState({isRecommend: !isRecommend})
    }

    onOpenToggleMenuButton = e => {
        this.setState({anchorEl: e.currentTarget});
    };

    onCloseToggleMenuButton = (stageID = false) => {
        if (_.isString(stageID)) {
            this.onClickAction(stageID)
        }
        this.setState({anchorEl: null});
    };


    onStore = async () => {
        const {dataUserComment, dataUserEvaluation, isRecommend, note} = this.state;
        const params = {
            PrivateNote: note,
            IsRecommend: isRecommend ? 1 : 0,
            data: JSON.stringify(dataUserEvaluation),
            comment: JSON.stringify(_.map(dataUserComment, item => _.omit(item, ['isEditing']))),
            ...this.params,
            ..._.pick(this.getCandidateInfo(), ["RecInfoID", "CandidateID", "InterviewFileID"]),
        }
        this.setState({isSaving: true});
        const res = await Api.put("/w25f2012/save", params);
        this.setState({isSaving: false});
        const messageErr = _.get(res, "message", "");
        const status = !_.get(res, "data.Status", 0);
        if (messageErr) {
            Config.popup.show("ERROR", messageErr)
            return false;
        }
        if (status) {
            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
            this.setState({isLoadingUserEvaluation: true, isLoadingCandidateInfo: true}, () => {
                this.loadUserEvaluation();
                this.loadInfoCandidate();
            })
            return true;
        }
        Config.popup.show("INFO", _.get(res, "data.Message", Config.lang("Loi_chua_xac_dinh")));
    }

    onClickAction = (StageID = false) => {
        const {classes, loadInfoCandidate, loadStage} = this.props;
        const oldCandidateInfo = this.state.dataCandidateInfo;
        if (!StageID) return false;
        const className = this.getStageName(StageID) === "Rejected" ? classes.iconDanger : classes.iconSuccess;
        Config.popup.show("YES_NO", `${Config.lang("Ban_muon")} <b class=${className}>${this.getStageName(StageID)}</b> ${Config.lang("ung_vien_nay")}`, () => {
            (async () => {
                this.setState({ isLoadingAction: true });

                const currentStageID = loadInfoCandidate?.[0]?.StageID ?? "";
                const currentStage = loadStage?.find(i => i.StageID === currentStageID) ?? {};
                const newStage = loadStage?.find(i => i.StageID === StageID) ?? {};

                const IsApprovalOffer = loadInfoCandidate?.[0]?.IsApprovalOffer ?? 0;
                const IsAutoSendmail = newStage?.IsAutoSendmail ?? 0;
                const TemplateMailID = newStage?.EmailTemplate ?? "";
                const res = await Api.put("/w25f2012/load-options", {
                    ...this.params,
                    ..._.omit(this.getCandidateInfo(), ["InterviewFileID"]),
                    StageID,
                    StageID_new: StageID ?? "",
                    StageID_old: currentStageID ?? "",
                    IsApprovalOffer,
                    IsAutoSendmail,
                    TemplateMailID,
                });
                const resMessgage = res?.message || "";
                const resDataMessage = res?.data?.Message || "";
                const resDataStatus = Boolean(res?.data?.Status);
                this.setState({ isLoadingAction: false });
                if (oldCandidateInfo?.IsApprovalOffer === 1 && StageID === "HIRED") {
                    if (resMessgage || resDataMessage) {
                        Config.popup.show("ERROR", resMessgage || resDataMessage);
                    } else this.setState({ showW25F2040Popup: true });
                } else {
                    if (resMessgage || resDataMessage) {
                        Config.popup.show("ERROR", resMessgage || resDataMessage);
                    }
                    if (!resDataStatus) {
                        const currentStageOrder = currentStage?.StageOrderNo ?? null;
                        const newStageOrder = newStage?.StageOrderNo ?? null;
                        if (currentStageOrder && newStageOrder && newStageOrder > currentStageOrder && IsAutoSendmail) {
                            this.handleOpenPopup("sendMail", true, { ...(loadInfoCandidate?.[0] ?? {}), newStageID: StageID });
                        } else {
                            Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                            this.setState({ isLoadingCandidateInfo: true }, () => {
                                this.loadInfoCandidate();
                            });
                        }
                    }
                }
            })();
        });
    }


    renderCandidateProfile = () => {
        const {classes, loadStage, loadInfoCandidate, loadOtherEvaluation} = this.props;
        const {dataUserEvaluation, isRecommend, isLoadingCandidateInfo, isReadOnly, messErrCandidateHired} = this.state;
        const candidateName = _.get(loadInfoCandidate, "[0]CandidateName", _.get(this.props, "data.CandidateName", ""));
        const candidatePictureURL = _.get(loadInfoCandidate, "[0]CandidatePictureURL", _.get(this.props, "data.CandidatePictureURL", ""));
        const urlPicture = candidatePictureURL ?
            (candidatePictureURL.indexOf("http") < 0
                ? Config.getCDNPath() + candidatePictureURL
                : candidatePictureURL)
            : ""
        const stageID = _.get(loadInfoCandidate, "[0]StageID", _.get(this.props, "data.StageID", ""));
        const arrEvaluation = [...dataUserEvaluation, ..._.get(loadOtherEvaluation, "[0]Evaluation", [])];
        const meanEpointCandidate = this.handleMeanEpoint(arrEvaluation);
        const numberRecommend = _.filter(loadOtherEvaluation, ["IsRecommend", 1]).length + (isRecommend ? 1 : 0);
        const stageNotRejected = _.filter(loadStage, item => item.StageID !== "REJECTED")
        const titleTooltip = <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
            {
                _.map(stageNotRejected, (item, i) => (
                    <span key={i} style={{
                        fontWeight: item.StageID === stageID ? 800 : 400,
                        color: "#FFF",
                        fontSize: "1.1 rem"
                    }}>
                        {item.StageName}
                    </span>
                ))
            }
        </Breadcrumbs>
        const stageWithValues = _.map(stageNotRejected, (item, i) => ({
            ...item,
            value: (i * 100) / (_.size(stageNotRejected) - 1)
        }));
        const stageCurrent = _.find(stageWithValues, o => o.StageID === stageID);
        const stageName = _.get(stageCurrent, "StageName", _.get(loadInfoCandidate, "[0]StageName", _.get(this.props, "data.StageName", "")))
        const isMoved = !!_.get(loadInfoCandidate, "[0]IsMoved", 0);
        const isEmployee = !!_.get(loadInfoCandidate, "[0]IsEmployee", 0);
        const titleTooltipIsMoved = !!isReadOnly ? messErrCandidateHired : Config.lang("Da_chuyen1");
        const titleTooltipIsEmployee = Config.lang("Ung_vien_da_duoc_cap_nhat_thong_tin_ung_tuyen_ban_khong_duoc_phep_chinh_sua_ma_ban_chi_duoc_phep_xem_thong_tin_ung_vien_nay")
        return (
            <div style={{position: "relative"}} className={classes.flexDRow}>
                <div className={classes.candidateAvatar}>
                    {!isLoadingCandidateInfo ? <UserImage
                        className={classes.boxShadow}
                        src={urlPicture}
                        width={120}
                        height={120}
                    /> : <Skeleton width={120} height={120} variant="circle" animation="wave"/>}
                </div>
                <div className={classes.flexDColumn} style={{width: "100%"}}>

                    <div className={classes.cardColumnCenter}>
                        <Typography
                            style={{textTransform: "uppercase", width: "100%"}}
                            className={classes.primaryText}
                            variant="h5"
                        >
                            {!isLoadingCandidateInfo ? candidateName : candidateName ?
                                <Skeleton width="60%" animation="wave"/> : ""}
                        </Typography>
                        <Typography
                            style={{textTransform: "uppercase", width: "100%"}}
                            className={classes.subText}
                            variant="subtitle1"
                        >
                            {!isLoadingCandidateInfo ? stageName : stageName ?
                                <Skeleton width="30%" animation="wave"/> : ""}
                        </Typography>
                    </div>
                    <Row>
                        <Col className={classes.colSlider} xs={12}>
                            <Tooltip classes={{tooltip: classes.tooltipSlider}} arrow title={titleTooltip}>
                                {!isLoadingCandidateInfo ? <div>
                                    {stageID === "REJECTED" ? (
                                        ""
                                    ) : (
                                        <Slider
                                            disabled={true}
                                            className={classes.slider}
                                            step={33.33}
                                            value={_.get(stageCurrent, "value", 0)}
                                            marks={stageWithValues}
                                        />
                                    )}
                                </div> : <Skeleton width="100%" animation="wave"/>}
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={9}>
                            {!isLoadingCandidateInfo ? <Rating
                                readOnly
                                className={classes.rating}
                                value={_.toNumber(meanEpointCandidate)}
                                precision={0.5}
                                size="large"
                            /> : <Skeleton width="45%" animation="wave"/>}
                        </Col>
                        <Col xs={3}>
                            <div
                                style={{justifyContent: "flex-end"}}
                                className={[
                                    classes.flexDRow,
                                    classes.subText
                                ].join(" ")}
                            >
                                <Typography
                                    className={classes.primaryText}
                                    variant="subtitle1"
                                >
                                    {!isLoadingCandidateInfo ? numberRecommend :
                                        <Skeleton width={15} animation="wave"/>}
                                </Typography>
                                <Icon
                                    style={{overflow: "visible", paddingLeft: 5, marginRight: 11}}
                                    fontSize="small"
                                    className="far fa-user-check"
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Tooltip
                        title={
                            <span style={{
                                fontWeight: 600,
                                color: "#FFF",
                                fontSize: "0.8rem"
                            }}>
                                {isMoved ? titleTooltipIsMoved : isEmployee ? titleTooltipIsEmployee : ""}
                            </span>
                        }
                        arrow>
                        <div className={isMoved || isEmployee ? classes.iconStatus : ""}>
                            {isMoved ? <ReplaceAllRight color={"white"} width={30}/> : isEmployee ?
                                <Info color={"white"} width={30}/> : ""}
                        </div>
                    </Tooltip>
                </div>
            </div>
        )
    }

    renderUserEvaluationSkeleton = (nLine = 0, wLabelLeft = 140, wLabelRight = 104, hContent = 60) => {
        const {classes} = this.props;
        return _.map(Array(nLine), (_, i) => (
            <Box key={i} mb={1}>
                <div className={classes.flexSpaceBetween}>
                    <Skeleton width={wLabelLeft} animation="wave"/>
                    <Skeleton width={wLabelRight} animation="wave"/>
                </div>
                <Skeleton height={hContent} animation="wave"/>
            </Box>)
        )
    }

    renderUserEvaluation = () => {
        const {classes, loadUserEvaluation} = this.props;
        const {dataUserComment, dataUserEvaluation, note, isRecommend, isSaving, isLoadingUserEvaluation} = this.state;
        const meanEpointUser = this.handleMeanEpoint(dataUserEvaluation);
        const getUserEvaluation = _.get(loadUserEvaluation, "[0]", loadUserEvaluation);
        return (
            <div className={classes.expansionPanelDetails}>
                <FormGroup>
                    <div className={classes.flexDRow}>
                        {!isLoadingUserEvaluation ? <UserImage
                                keyExpr={"UserID"}
                                valueExpr={"UserID"}
                                allowHover={true}
                                data={getUserEvaluation}
                                width={45}
                                height={45}/> :
                            <Skeleton style={{marginRight: 8}} animation="wave" variant="circle" width={45}
                                      height={45}/>}
                        <div className={classes.flexDColumn} style={{width: "100%"}}>
                            {!isLoadingUserEvaluation ?
                                <UserName allowHover={false} keyExpr={"UserID"} valueExpr={"UserID"}
                                          data={getUserEvaluation}/> : <Skeleton animation="wave" width="45%"/>}
                            <div className={classes.flexDRow}>
                                <Image
                                    style={{marginRight: 5}}
                                    src={require("../../../../assets/images/icon-calendar.svg")}
                                />
                                <Typography
                                    style={{width: "100%"}}
                                    className={classes.subText}
                                    variant="subtitle1"
                                >
                                    {!isLoadingUserEvaluation ? moment.utc(_.get(getUserEvaluation, "ECreateDate", moment())).format("DD/MM/YYYY, kk:mm:ss") :
                                        <Skeleton animation="wave" width="43.5%"/>}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </FormGroup>
                <FormGroup>
                    <label
                        className={classes.subText}>{Config.lang("Nhan_xet_ung_vien")}</label>
                    {!isLoadingUserEvaluation ? <TextField
                        fullWidth
                        className={classes.textFieldOutlined}
                        value={note}
                        multiline
                        variant="outlined"
                        onChange={this.onChangeNote}
                        rows={8}
                        disabled={isSaving}
                    /> : <Skeleton variant="rect" height={159} animation="wave"/>}
                </FormGroup>
                <FormGroup>
                    <div className={classes.tlContainer}>
                        <div className={[classes.tl, classes.subText].join(" ")}>
                            {_.map(dataUserComment, (item, i) => (
                                <div key={i} className={classes.timeLine}>
                                    <div key={i} className={classes.containerTimeline}>
                                        <div className={classes.contentTimeline}>
                                            <Typography
                                                className={this.clsx(classes.flexDRow, classes.subText)}
                                                variant="subtitle2"
                                                component="div"
                                            >
                                                {moment.utc(_.get(item, "CreateDate", "")).format("DD/MM/YYYY, kk:mm:ss")}
                                                <div
                                                    className={this.clsx(
                                                        classes.buttonActionComment,
                                                        item.isEditing
                                                            ? classes.editingComment
                                                            : classes.hoverActionTimeline
                                                    )}
                                                >
                                                    <IconButton
                                                        disabled={false}
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => {
                                                            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
                                                                this.handleActionComment(i, "remove")
                                                            })
                                                        }}
                                                    >
                                                        <Icons name={"delete"}/>
                                                    </IconButton>
                                                    {item.isEditing ? (
                                                        <>
                                                            <IconButton
                                                                disabled={isSaving}
                                                                aria-label="redo"
                                                                size="small"
                                                                onClick={() =>
                                                                    this.handleActionComment(i, "isEditing", false)
                                                                }
                                                            >
                                                                <Icons
                                                                    fontSize="small"
                                                                    className={this.clsx("far fa-check", classes.iconCheck)}
                                                                />
                                                            </IconButton>
                                                            <IconButton
                                                                disabled={item.Content === _.get(this.oldDataUserComment, `[${i}].Content`, "") || isSaving}
                                                                aria-label="redo"
                                                                size="small"
                                                                onClick={() =>
                                                                    this.handleActionComment(i, "redo")
                                                                }
                                                            >
                                                                <Icons
                                                                    fontSize="small"
                                                                    className={this.clsx("far fa-redo", classes.iconRedo)}
                                                                />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <IconButton
                                                            disabled={isSaving}
                                                            aria-label="edit"
                                                            size="small"
                                                            onClick={() =>
                                                                this.handleActionComment(i, "isEditing", true)
                                                            }
                                                        >
                                                            <Icons name={"edit"}/>
                                                        </IconButton>
                                                    )}
                                                </div>
                                            </Typography>
                                            <TextField
                                                autoFocus={item.isEditing}
                                                margin="none"
                                                disabled={!item.isEditing || isSaving}
                                                className={this.clsx(
                                                    classes.inputComment,
                                                    classes.primaryText,
                                                    item.isEditing ? "" : classes.editedComment
                                                )}
                                                value={item.Content}
                                                multiline
                                                variant="outlined"
                                                onChange={e =>
                                                    this.handleActionComment(
                                                        i,
                                                        "Content",
                                                        _.get(e, "target.value", "")
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className={classes.flexDRow}
                        style={{width: "100%", justifyContent: "center"}}
                    >
                        <IconButton
                            onClick={() => this.handleActionComment(_, "add")}
                        >
                            <Icons name={"add"}/>
                        </IconButton>
                    </div>
                </FormGroup>
                <Box mb={2} className={classes.dashedBox}>
                    <Box mb={1}>
                        <Typography className={classes.subText} variant="subtitle1">
                            {Config.lang("Cham_diem_theo_tieu_chi")}
                        </Typography>
                    </Box>
                    {!isLoadingUserEvaluation ? _.map(dataUserEvaluation, (item, i) => (
                        <Box key={i} mb={1}>
                            <div className={classes.flexSpaceBetween}>
                                <Typography
                                    className={classes.subText}
                                    variant="subtitle1"
                                >
                                    {`• ${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", 1) || 1})`}
                                </Typography>
                                <Tooltip arrow title={`${Number(_.get(item, "EPoint", ""))} / 5`}>
                                    <Rating
                                        name={`rating -${i}`}
                                        className={classes.rating}
                                        value={Number(_.get(item, "EPoint", 0))}
                                        onChange={(_, newValue) => {
                                            this.onChangeEvaluation(i, newValue)
                                        }}
                                        precision={0.5}
                                        disabled={isSaving}
                                    />
                                </Tooltip>
                            </div>
                            <TextField
                                fullWidth
                                className={classes.textFieldOutlined}
                                value={_.get(item, "Notes", "") || ""}
                                variant="outlined"
                                disabled={isSaving}
                                onChange={e => this.onChangeEvaluation(i, _.get(e, "target.value", ""), "Notes")}
                            />
                        </Box>
                    )) : this.renderUserEvaluationSkeleton(dataUserEvaluation.length)}
                </Box>
                <Box mb={2} className={classes.dashedBox}>
                    <Typography
                        className={[classes.titleScore, classes.subText].join(" ")}
                        variant="subtitle1"
                    >
                        {Config.lang("Diem_trung_binh")}
                    </Typography>
                    <div className={[classes.flexDColumn, classes.ratingScore].join(
                        " "
                    )}
                    >
                        {!isLoadingUserEvaluation ? <Rating
                            readOnly
                            size="large"
                            className={classes.rating}
                            value={_.toNumber(meanEpointUser)}
                            precision={0.5}
                        /> : <Skeleton width={130} animation="wave"/>}
                        {!isLoadingUserEvaluation ? <div className={classes.flexDRow}>
                            <Typography
                                className={classes.primaryText}
                                variant="subtitle1"
                            >
                                {meanEpointUser}
                            </Typography>
                            /
                            <Typography
                                className={classes.subText}
                                variant="subtitle1"
                            >
                                5.0
                            </Typography>
                        </div> : <Skeleton animation="wave" width={30}/>}
                    </div>
                </Box>
                <Box mb={2} className={classes.dashedBox}>
                    <Typography
                        component="div"
                        className={classes.subText}
                        variant="subtitle1"
                    >
                        {Config.lang("De_nghi_tuyen")}
                        <Typography component="div" className={classes.flexDRow}>
                            <Checkbox
                                checked={isRecommend}
                                color="primary"
                                icon={<RadioButtonUncheckedIcon color="primary"/>}
                                checkedIcon={<RadioButtonCheckedIcon color="primary"/>}
                                onChange={this.onChangeIsRecommend}
                                disabled={isSaving}
                            />
                            <Typography variant="subtitle1">
                                {Config.lang("De_nghi_tuyen_ung_vien_nay")}
                            </Typography>
                        </Typography>
                    </Typography>
                </Box>
            </div>
        )
    }

    renderOtherEvaluation = (data = []) => {
        const {classes, loadOtherEvaluation} = this.props;
        const {isLoadingOtherEvaluation} = this.state;
        return (_.map(data,
            (itemUser, i) => {
                const arrOtherEvaluation = _.get(itemUser, "Evaluation", []);
                const arrOtherComment = _.get(itemUser, "Comment", []);
                const meanEpointOther = this.handleMeanEpoint(arrOtherEvaluation)
                return (
                    <FormGroup key={i}>
                        <div style={{width: "100%"}} className={classes.flexDColumn}>
                            <Box
                                mb={1}
                                className={[classes.flexDRow, classes.boxInfoUser].join(" ")}
                            >
                                <div
                                    style={{justifyContent: "center"}}
                                    className={classes.flexDRow}
                                >
                                    {!isLoadingOtherEvaluation ?
                                        <UserImage keyExpr={"UserID"} valueExpr={"UserID"} allowHover={true}
                                                   data={itemUser}
                                                   width={45} height={45}/> :
                                        <Skeleton style={{marginRight: 8}} animation="wave" variant="circle" width={45}
                                                  height={45}/>}
                                    <div className={classes.flexDColumn}>
                                        {!isLoadingOtherEvaluation ?
                                            <UserName keyExpr={"UserID"} valueExpr={"UserID"} data={itemUser}
                                                      allowHover={false}/> : <Skeleton animation="wave" width={200}/>}
                                        <div className={classes.flexDRow}>
                                            <Image
                                                style={{marginRight: 5}}
                                                src={require("../../../../assets/images/icon-calendar.svg")}
                                            />
                                            <Typography
                                                className={classes.subText}
                                                variant="subtitle1"
                                            >
                                                {!isLoadingOtherEvaluation ? moment.utc(_.get(itemUser, "ECreateDate", moment())).format("DD/MM/YYYY, kk:mm:ss") :
                                                    <Skeleton animation="wave" width={183}/>}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.flexDRow}>
                                    {_.get(itemUser, "IsRecommend", 0) ? <Icon
                                        style={{overflow: "visible", marginRight: 10}}
                                        fontSize="small"
                                        className="far fa-user-check"
                                    /> : ""}
                                    {!isLoadingOtherEvaluation ?
                                        <Avatar
                                            className={classes.avatarPoint}>{Number(meanEpointOther)}</Avatar> :
                                        <Skeleton animation="wave" variant="circle" width={40} height={40}/>}
                                </div>
                            </Box>
                            <Box className={classes.dashedBox}>
                                {!isLoadingOtherEvaluation ? _.map(arrOtherEvaluation, (item, i) => (
                                    <Box key={i} mb={1}>
                                        <div className={classes.flexSpaceBetween}>
                                            <Typography
                                                className={classes.subText}
                                                variant="subtitle1"
                                            >
                                                {`• ${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", 1) || 1})`}
                                            </Typography>
                                            <Tooltip title={`${_.get(item, "EPoint", "") || 0} / 5`}>
                                                <Rating
                                                    readOnly
                                                    name={`rating -${i}`}
                                                    className={classes.rating}
                                                    value={Number(_.get(item, "EPoint", ""))}
                                                    precision={0.5}
                                                />
                                            </Tooltip>
                                        </div>
                                        <label className={"MuiInputBase-root"}>{_.get(item, "Notes", "") || ""}</label>
                                    </Box>
                                )) : this.renderUserEvaluationSkeleton(arrOtherEvaluation.length)}
                            </Box>
                            <Box className={classes.dashedBox}
                                 style={{paddingTop: 24, paddingBottom: 0}}>
                                <div className={[classes.tl, classes.subText].join(" ")}>
                                    {_.map(arrOtherComment, (item, i) => (
                                        <div key={i} className={classes.timeLine}>
                                            <div className={classes.containerTimeline}>
                                                <div className={classes.contentTimeline}
                                                     style={{transform: "translateY(-6px)"}}>
                                                    <Typography
                                                        className={[classes.flexDRow, classes.subText].join(
                                                            " "
                                                        )}
                                                        variant="subtitle2"
                                                        component="div"
                                                    >
                                                        {moment.utc(_.get(item, "CreateDate", "")).format("DD/MM/YYYY, kk:mm:ss")}
                                                    </Typography>
                                                    <label
                                                        className={"MuiInputBase-root"}>{_.get(item, "Content", "")}</label>
                                                </div>
                                            </div>

                                        </div>))
                                    }
                                </div>
                            </Box>
                        </div>
                        {(i + 1) === loadOtherEvaluation.length ? "" : <Divider/>}
                    </FormGroup>
                )
            }
        ))
    }

    renderGeneralEvaluation = () => {
        const {classes, loadOtherEvaluation} = this.props;
        const {dataUserEvaluation, isLoadingOtherEvaluation, isLoadingUserEvaluation,} = this.state;
        const arrEvaluation = [...dataUserEvaluation, ..._.get(loadOtherEvaluation, "[0]Evaluation", [])];
        const arrArrgEpoint = _.reduce(arrEvaluation, (acc, itemCurrent) => {
            let index = _.findIndex(acc, o => o.EvaluationElementID === itemCurrent.EvaluationElementID);
            if (index === -1) acc = [...acc, {...itemCurrent, divisor: 1}];
            if (index !== -1) {
                acc[index] = {
                    ...acc[index],
                    EPoint: Number(_.get(acc, `[${index}].EPoint`, 0)) + Number(_.get(itemCurrent, "EPoint", 0)),
                    divisor: Number(_.get(acc, `[${index}].divisor`, 0)) + 1
                };
            }
            return acc;
        }, []);
        return (
            <div style={{width: "100%"}} className={classes.flexDColumn}>
                {!isLoadingOtherEvaluation && !isLoadingUserEvaluation ? _.map(arrArrgEpoint, (item, i) => {
                    const arrgEpoint = item.EPoint / item.divisor;
                    return (
                        <div key={i} className={classes.labelProgress}>
                            <Box mb={1}>
                                <div
                                    className={[
                                        classes.flexSpaceBetween,
                                        classes.subText
                                    ].join(" ")}
                                >
                                    <Typography
                                        className={classes.subText}
                                        variant="subtitle1"
                                    >
                                        {`${_.get(item, "EvaluationElementName", "") || ""}(x${_.get(item, "Proportion", "") || 1})`}
                                    </Typography>
                                    <div className={classes.flexDRow}>
                                        <Typography
                                            className={classes.primaryText}
                                            variant="subtitle1"
                                        >
                                            {arrgEpoint.toFixed(1)}
                                        </Typography>
                                        /
                                        <Typography
                                            className={classes.subText}
                                            variant="subtitle1"
                                        >
                                            5.0
                                        </Typography>
                                    </div>
                                </div>
                            </Box>
                            <LinearProgress
                                className={classes.progress}
                                variant="determinate"
                                value={arrgEpoint * 20}
                            />
                        </div>
                    )
                }) : this.renderUserEvaluationSkeleton(arrArrgEpoint.length, 140, 60, 25)
                }
            </div>
        )
    }

    closePopup = () => {
        this.setState({ showW25F2040Popup: false });

        this.setState({isLoadingCandidateInfo: true}, () => {
            this.loadInfoCandidate();
        })
    };

    handleOpenPopup = (name, flag = false, data) => {
        switch (name) {
            case 'sendMail':
                if (!flag) {
                    setTimeout(() => {
                        Config.notify.show('success', Config.lang("Luu_thanh_cong"), data ? 4000 : 2000);
                        this.setState({ isLoadingCandidateInfo: true }, () => {
                            this.loadInfoCandidate();
                        });
                    }, data ? 2000 : 0);
                }
                this.setState({ isOpenSendMailPop: flag, currentStage: data ?? {} });
                break;
            default:
                break;
        }
    };

    render() {
        const {
            classes,
            loadStage,
            loadInfoCandidate,
            loadUserEvaluation,
            loadOtherEvaluation,
            isViewOnly,
        } = this.props;
        const {
            anchorEl,
            isSaving,
            isLoadingCandidateInfo,
            isLoadingUserEvaluation,
            isLoadingAction,
            isReadOnly,
            isOpenSendMailPop,
            currentStage,
        } = this.state;
        const expansionClassName = this.clsx(classes.expansionCard, classes.boxShadowCard);
        const expansionSummaryClassName = {
            root: classes.expansionSummaryRoot,
            content: classes.expansionSummaryContent
        };
        const stageID = _.get(loadInfoCandidate, "[0]StageID", _.get(this.props, "data.StageID", ""));
        const iStateIDNext = _.findIndex(loadStage, o => o.StageID === stageID) + 1;
        const getStateIDNext = _.get(loadStage, `[${iStateIDNext}]`, false);
        const arrOfStageBtn = _.filter(
            [
                {
                    stageID: "REJECTED",
                    name: Config.lang("Loai_nhanh"),
                    icon: "fas fa-user-minus",
                    className: classes.btnDanger,
                    isVisible: stageID !== "empty" && !(stageID === "HIRED" || stageID === "REJECTED")
                },
                {
                    stageID: getStateIDNext ? getStateIDNext.StageID : "",
                    name: Config.lang("Chuyen_tiep"),
                    icon: "far fa-forward",
                    className: classes.btnSuccess,
                    isVisible: stageID !== "empty" && !(stageID === "HIRED" || stageID === "REJECTED")
                },
                {
                    stageID: null,
                    name: this.getStageName("HIRED"),
                    icon: "far fa-check",
                    className: classes.btnSuccess,
                    isVisible: stageID !== "empty" && stageID === "HIRED"
                },
                {
                    stageID: null,
                    name: this.getStageName("REJECTED"),
                    icon: "far fa-check",
                    className: classes.btnDanger,
                    isVisible: stageID !== "empty" && stageID === "REJECTED"
                }
            ],
            "isVisible"
        );
        const userEvaluation = _.filter(_.map([...loadOtherEvaluation, ...loadUserEvaluation], itemUser => ({
            ...itemUser,
            isEmptyEvaluation: _.some(
                _.get(itemUser, "Evaluation", []),
                item => _.isEmpty(_.get(item, "Notes", "")) && _.isNil(_.get(item, "EPoint", null))
            )
        })), item => !Boolean(item.isEmptyEvaluation));
        const isMovedAndEmployee = !!_.get(loadInfoCandidate, "[0]IsMoved", 0) || !!_.get(loadInfoCandidate, "[0]IsEmployee", 0);
        const RecInfoID = _.get(loadInfoCandidate, "[0]RecInfoID", "");
        const CandidateID = _.get(loadInfoCandidate, "[0]CandidateID", "");
        const DecisionID = _.get(loadInfoCandidate, "[0]DecisionID", "");
        const ApproveLevel = _.get(loadInfoCandidate, "[0]ApproveLevel", 0);
        const ApprovalStatus = _.get(loadInfoCandidate, "[0]ApprovalStatus", 0);
        const isApproving = (ApproveLevel > 1 && ApprovalStatus === 0);
        return (
            <>
                <W25F2040
                    open={this.state.showW25F2040Popup}
                    onClose={this.closePopup}
                    RecInfoID={RecInfoID || ""}
                    CandidateID={CandidateID|| ""}
                    TransID={DecisionID|| ""}
                    mode={0}
                    readOnly={(ApproveLevel !== 1 || ApprovalStatus !== 0)}
                    fullCandidateInfo={this.props.fullCandidateInfo}
                />
                <FormGroup>
                    <Card variant="outlined" className={classes.boxShadowCard}>
                        <CardContent>
                            {this.renderCandidateProfile()}
                        </CardContent>
                        <CardActions style={{padding: "8px 9px"}}>
                            <div className={this.clsx(classes.flexDRow, classes.buttonAction)}>
                                {_.map(arrOfStageBtn, (item, i) => (
                                    <ButtonCustom
                                        key={i}
                                        name={item.name}
                                        variant="contained"
                                        style={{textTransform: "uppercase"}}
                                        // size={"medium"}
                                        onClick={() => this.onClickAction(item.stageID)}
                                        className={this.clsx(classes.buttonStage, item.className)}
                                        icon={<Icon fontSize={"small"} className={item.icon}/>}
                                        disabled={isSaving || isLoadingCandidateInfo || isLoadingAction || isMovedAndEmployee || isReadOnly || isApproving || isViewOnly}
                                    />
                                ))}
                                {_.size(loadInfoCandidate) > 0 ?
                                    <>
                                        <Button
                                            // size={"medium"}
                                            style={{textTransform: "uppercase"}}
                                            className={classes.buttonStage}
                                            endIcon={<Icon fontSize={"small"} className="far fa-angle-down"/>}
                                            disabled={isReadOnly || isSaving || isLoadingCandidateInfo || isLoadingAction || isMovedAndEmployee || isApproving || isViewOnly}
                                            variant="outlined"
                                            color="primary"
                                            onClick={this.onOpenToggleMenuButton}
                                        >
                                            {Config.lang("Lua_chon")}
                                        </Button>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.onCloseToggleMenuButton}
                                        >
                                            {_.map(loadStage, (item, i) => {
                                                    const getStageID = _.get(item, "StageID", "");
                                                    const getStageName = _.get(item, "StageName", "");
                                                    return (
                                                        <MenuItem
                                                            disabled={item.StageID === stageID}
                                                            key={i}
                                                            onClick={() => this.onCloseToggleMenuButton(item.StageID)}>
                                                            <div className={classes.flexDRow}>
                                                                <FiberManualRecordIcon
                                                                    className={getStageID === "REJECTED" ? classes.iconDanger : classes.iconSuccess}/>
                                                                <div className={classes.flexDRow} style={{paddingLeft: 3}}>
                                                                    <Typography variant="subtitle2"
                                                                                className={classes.primaryText}>
                                                                        {getStageID === "REJECTED" ? getStageID : `${i + 1} / ${loadStage.length}:`}
                                                                    </Typography>
                                                                    <Typography variant="subtitle2"
                                                                                className={classes.subText}
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                    whiteSpace: "pre-line"
                                                                                }}>
                                                                        {getStageName}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </MenuItem>
                                                    )
                                                }
                                            )}
                                        </Menu>
                                    </> : ""}
                            </div>
                        </CardActions>
                    </Card>
                </FormGroup>
                <FormGroup>
                    <Accordion defaultExpanded className={expansionClassName}>
                        <AccordionSummary
                            classes={expansionSummaryClassName}
                            IconButtonProps={{size: "small"}}
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography variant="h6">
                                {Config.lang("Ghi_chu_phong_van")}
                            </Typography>
                        </AccordionSummary>
                        <Divider variant="middle"/>
                        <AccordionDetails>
                            {this.renderUserEvaluation()}
                        </AccordionDetails>
                        <Divider variant="middle"/>
                        <AccordionActions>
                            <ButtonGeneral
                                name={Config.lang("Luu")}
                                typeButton={"save"}
                                className={["pull-right", classes.btnSave].join(" ")}
                                loading={isSaving}
                                disabled={isLoadingUserEvaluation || isSaving || isMovedAndEmployee || isReadOnly || isApproving || isViewOnly}
                                size={"small"}
                                onClick={this.onStore}
                            />
                        </AccordionActions>
                    </Accordion>
                </FormGroup>
                <FormGroup>
                    <Accordion defaultExpanded className={expansionClassName}>
                        <AccordionSummary
                            classes={expansionSummaryClassName}
                            expandIcon={<ExpandMoreIcon/>}
                            IconButtonProps={{size: "small"}}
                        >
                            <Typography variant="h6">
                                {Config.lang("Tong_hop_danh_gia")}
                            </Typography>
                        </AccordionSummary>
                        <Divider variant="middle"/>
                        <AccordionDetails>
                            {this.renderGeneralEvaluation()}
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                <FormGroup>
                    <Accordion defaultExpanded className={expansionClassName}>
                        <AccordionSummary
                            classes={expansionSummaryClassName}
                            expandIcon={<ExpandMoreIcon/>}
                            IconButtonProps={{size: "small"}}
                        >
                            <Typography variant="h6">{`${Config.lang(
                                "Tat_ca_danh_gia"
                            )} (${_.size(userEvaluation)})`}</Typography>
                        </AccordionSummary>
                        <Divider variant="middle"/>
                        <AccordionDetails classes={{root: classes.flexDColumn}}>
                            {this.renderOtherEvaluation(userEvaluation)}
                        </AccordionDetails>
                    </Accordion>
                </FormGroup>
                {isOpenSendMailPop && <SendMailPop open={isOpenSendMailPop} currentStage={currentStage} handleOpenPopup={this.handleOpenPopup} />}
            </>
        );
    }

    componentDidUpdate(prevProps) {
        if (_.get(this.props, "data.CandidateID", "") !== _.get(prevProps, "data.CandidateID", "")) {
            this.setState({
                isLoadingCandidateInfo: true,
                isLoadingUserEvaluation: true,
                isLoadingOtherEvaluation: true,
            });
            this.loadInfoCandidate();
            this.loadStage();
            this.loadUserEvaluation();
            this.loadOtherEvaluation();
        }
        if (_.get(this.props, "recInfoIDChange", "") !== _.get(prevProps, "recInfoIDChange", "")) {
            this.setState({
                isLoadingCandidateInfo: true,
            });
            this.loadInfoCandidate();
        }
    }

    componentDidMount = () => {
        this.setState({
            isLoadingCandidateInfo: true,
            isLoadingUserEvaluation: true,
            isLoadingOtherEvaluation: true,
        });
        this.loadInfoCandidate();
        this.loadStage();
        this.loadUserEvaluation();
        this.loadOtherEvaluation();
    }
    ;

    componentWillUnmount = () => {
        this.oldDataUserComment = null;
        clearTimeout(this.timerCandidateInfo);
        clearTimeout(this.timerUserEvaluaton);
        clearTimeout(this.timerOtherEvaluation);
    }
}

W25F2012.propTypes = {
    data: PropTypes.object
};

W25F2012.defaultProps = {
    isViewOnly: false,
};

export default compose(connect(state => ({
        getCboAppStatus: state.general.getCboAppStatus,
        loadStage: state.W25F2012.loadStage,
        loadInfoCandidate: state.W25F2012.loadInfoCandidate,
        loadUserEvaluation: state.W25F2012.loadUserEvaluation,
        loadOtherEvaluation: state.W25F2012.loadOtherEvaluation,
    }),
    dispatch => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        w25f2012Actions: bindActionCreators(W25F2012Actions, dispatch)
    })),
    withStyles(styles, {withTheme: true}))(W25F2012);
